// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legislation-js": () => import("./../../../src/pages/legislation.js" /* webpackChunkName: "component---src-pages-legislation-js" */),
  "component---src-pages-news-strapi-articles-slug-js": () => import("./../../../src/pages/news/{strapiArticles.slug}.js" /* webpackChunkName: "component---src-pages-news-strapi-articles-slug-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

